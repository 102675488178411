<template>
  <b-form-group
    label="İl"
    label-for="id_cities"
  >
    <v-select
      id="id_cities"
      v-model="dataItem.id_cities"
      :options="cities"
      label="title"
      :reduce="city => city.id"
      placeholder="Seçiniz"
      :disabled="cities.length === 0"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Cities',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/dataItem']
    },
    cities() {
      return this.$store.getters['cities/dataList']
    },
  },
}
</script>
