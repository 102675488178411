<template>
  <b-form-group
    label="İlgili Kişi"
    label-for="name"
  >
    <b-form-input
      id="name"
      v-model="dataItem.name"
      placeholder="İlgili Kişi"
    />
  </b-form-group>
</template>
<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'Name',
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/dataItem']
    },
  },
}
</script>
